<template>
  <div class="first-col">
    <div class="group">
      <span class="group-title"
        ><i class="fa fa-user"></i>{{ $t("my_information") }}</span
      >
      <div class="wrap-box">
        <b-form-group
          :label="$t('form_email') + '*'"
          label-for="email"
          description="De bevestiging wordt naar dit e-mailadres verzonden."
          class="email-with-note"
        >
          <b-form-input
            id="email"
            v-model="form.email"
            type="text"
            :state="form.emailState"
            aria-describedby="email-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="email-feedback">
            {{ form.emailError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div v-if="emailExists" class="password-field">
          <b-form-group
            class="account-inputs"
            id="password-group"
            :label="$t('form_password') + '*'"
            label-for="password"
          >
            <b-form-input
              key="password"
              id="password"
              v-model="form.password"
              type="password"
              aria-describedby="password-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="password-feedback">
              {{ form.passwordError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="bottom">
            <b-link to="/forgot-password" class="forgot-link">{{
              $t("password_forgot")
            }}</b-link>
            <b-button
              type="button"
              @click="doLogin"
              class="c-btn c-btn-primary"
              >{{ $t("login") }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="group">
      <span class="group-title"
        ><i class="fas fa-home"></i>{{ $t("shipping_address") }}</span
      >
      <div class="wrap-box">
        <b-form-checkbox
          id="checkbox-1"
          v-model="isCompany"
          name="checkbox-1"
          >{{ $t("form_are_you_a_company") }}</b-form-checkbox
        >
        <div v-if="isCompany" class="short-form">
          <b-form-group
            class="account-inputs"
            id="company-group"
            key="company"
            :label="$t('form_company_name') + '*'"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="form.company"
              type="text"
              v-on:blur="validateCompany"
              :state="form.companyState"
              aria-describedby="company-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="company-feedback">
              {{ form.companyError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="account-inputs"
            id="coc_number-group"
            key="coc_number"
            :label="$t('form_coc_number') + '*'"
            label-for="coc_number"
          >
            <b-form-input
              id="coc_number"
              v-model="form.coc_number"
              type="text"
              v-on:blur="validateCOCNumber"
              :state="form.coc_numberState"
              aria-describedby="coc_number-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="coc_number-feedback">
              {{ form.coc_numberError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            class="account-inputs"
            id="taxvat-group"
            key="taxvat"
            :label="$t('form_vat_number') + '*'"
            label-for="taxvat"
          >
            <b-form-input
              id="taxvat"
              v-model="form.taxvat"
              type="text"
              :state="form.taxvatState"
              aria-describedby="taxvat-feedback"
              @blur="validateVAT"
            ></b-form-input>
            <b-form-invalid-feedback id="taxvat-feedback">
              {{ form.taxvatError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- <b-form-group
            class="account-inputs"
            id="taxvat-group"
            :label="$t('form_vat_number')"
            label-for="taxvat"
          >
            <b-form-input
              id="taxvat"
              v-model="form.taxvat"
              type="text"
              :state="form.taxvatState"
              :disabled="isValidVatNumber === false"
              aria-describedby="taxvat-feedback"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="showVatMessage != ''"
              id="taxvat-feedback"
            >
              {{ form.showVatMessage }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-else id="taxvat-feedback">
              {{ form.taxvatError }}
            </b-form-invalid-feedback>
          </b-form-group> -->
        </div>

        <b-form-group
          class="account-inputs"
          id="first_name-group"
          key="first_name"
          :label="$t('form_first_name') + '*'"
          label-for="first_name"
        >
          <b-form-input
            id="first_name"
            v-model="form.firstname"
            type="text"
            v-on:blur="validateFirstname"
            :state="form.firstnameState"
            aria-describedby="firstname-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="firstname-feedback">
            {{ form.firstnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="last_name-group"
          key="last_name"
          :label="$t('form_last_name') + '*'"
          label-for="last_name"
        >
          <b-form-input
            id="last_name"
            v-model="form.lastname"
            type="text"
            v-on:blur="validateLastname"
            :state="form.lastnameState"
            aria-describedby="lastname-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="lastname-feedback">
            {{ form.lastnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="country-group"
          :label="$t('form_country') + '*'"
          label-for="country"
        >
          <b-form-select
            id="country"
            v-model="form.address.country_code"
            :options="countries"
            class="select"
          ></b-form-select>
        </b-form-group>
        <!-- v-if="!showFullAddress" -->
        <b-form-group
          class="account-inputs"
          id="postcode-group"
          key="postcode"
          :label="$t('form_postal_code') + '*'"
          label-for="postcode"
        >
          <b-form-input
            id="postcode"
            v-model="form.address.postcode"
            type="text"
            :state="form.address.postcodeState"
            aria-describedby="address.postcode-feedback"
            v-on:blur="validatePostcode"
          ></b-form-input>
          <b-form-invalid-feedback id="address.postcode-feedback">
            {{ form.address.postcodeError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="showFullAddress"
          class="account-inputs"
          id="street-group"
          key="street"
          :label="$t('form_street') + '*'"
          label-for="street"
        >
          <b-form-input
            id="street"
            v-model="form.address.streetDisplay"
            type="text"
            v-on:blur="validateStreet"
            :state="form.address.streetDisplayState"
            aria-describedby="address.streetDisplay-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="address.streetDisplay-feedback">
            {{ form.address.streetDisplayError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-row>
          <b-col sm="6" class="first">
            <b-form-group
              class="account-inputs"
              id="house_number-group"
              key="house_number"
              :label="$t('form_house_number') + '*'"
              label-for="house_number"
            >
              <b-form-input
                id="house_number"
                v-model="form.address.house_number"
                type="text"
                v-on:blur="validateHouseNumber"
                :state="form.address.house_numberState"
                aria-describedby="address.house_number-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="address.house_number-feedback">
                {{ form.address.house_numberError }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="6" class="third">
            <b-form-group
              class="account-inputs"
              id="addition-group"
              key="addition"
              :label="$t('form_addition')"
              label-for="addition"
            >
              <b-form-input
                id="addition"
                v-model="form.address.addition"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- <b-form-group
          v-if="showFullAddress"
          class="account-inputs"
          id="postcode-group1"
          :label="$t('form_postal_code') + '*'"
          label-for="postcode1"
        >
          <b-form-input
            id="postcode1"
            v-model="form.address.postcode"
            type="text"
            v-on:blur="validatePostcode()"
            :state="form.address.postcodeState"
            aria-describedby="address.postcode1-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="address.postcode1-feedback">
            {{ form.address.postcodeError }}
          </b-form-invalid-feedback>
        </b-form-group> -->

        <b-form-group
          v-if="showFullAddress"
          class="account-inputs"
          id="city-group"
          key="city"
          :label="$t('form_city') + '*'"
          label-for="city"
        >
          <b-form-input
            id="city"
            v-model="form.address.city"
            type="text"
            v-on:blur="validateCity"
            :state="form.address.cityState"
            aria-describedby="address.city-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="address.city-feedback">
            {{ form.address.cityError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="foundAddress != '' && showFullAddress == false"
          class="account-inputs"
          id="add-group"
          label=" "
          label-for="add"
        >
          <div
            id="add"
            :style="{ color: hasFoundAddress === false && '#CB2E25' }"
          >
            {{ foundAddress }}
          </div>
        </b-form-group>
        <b-form-group
          v-if="foundAddresses.length > 0 && showFullAddress == false"
          class="account-inputs"
          id="addm-group"
          label=" "
          label-for="addm"
        >
          <div id="addm">
            <b-form-radio
              style="width: 300px"
              class="account-radios"
              v-model="selectedAddress"
              v-for="(item, index) in foundAddresses"
              :key="`nl-${index}`"
              name="some-radios"
              :value="index"
              >{{ item.text }}</b-form-radio
            >
          </div>
        </b-form-group>
        <b-form-checkbox
          v-if="showManual"
          id="manual"
          v-model="showFullAddress"
          name="manual"
          >{{ $t("form_fillout_address_manual") }}</b-form-checkbox
        >
        <b-form-group
          class="account-inputs"
          id="telephone-group"
          key="telephone"
          :label="$t('form_phone') + '*'"
          label-for="telephone"
        >
          <b-form-input
            id="telephone"
            v-model="form.address.telephone"
            type="text"
            v-on:blur="validatePhone"
            :state="form.address.telephoneState"
            aria-describedby="address.telephone-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="address.telephone-feedback">
            {{ form.address.telephoneError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-checkbox
          id="checkbox-2"
          name="checkbox-2"
          label-for="checkbox2"
          v-model="form.address.default_billing"
          >{{ $t("form_address_also_invoice_address") }}</b-form-checkbox
        >
      </div>
    </div>
    <div class="group" v-if="form.address.default_billing == false">
      <span class="group-title"
        ><i class="fas fa-home"></i>{{ $t("invoice_address") }}</span
      >
      <div class="wrap-box">
        <b-form-checkbox
          id="checkbox-99"
          v-model="isInvoiceCompany"
          name="checkbox-99"
          >{{ $t("form_are_you_a_company") }}</b-form-checkbox
        >
        <div v-if="isInvoiceCompany" class="short-form">
          <b-form-group
            class="account-inputs"
            id="company-group"
            key="invoice-company"
            :label="$t('form_company_name') + '*'"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="form.invoice.company"
              type="text"
              v-on:blur="validateInvoiceCompany"
              :state="form.invoice.companyState"
              aria-describedby="company-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="company-feedback">
              {{ form.invoice.companyError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            class="account-inputs"
            id="coc_number-group"
            key="invoice-coc_number"
            :label="$t('form_coc_number') + '*'"
            label-for="coc_number"
          >
            <b-form-input
              id="coc_number"
              v-model="form.invoice.coc_number"
              type="text"
              v-on:blur="validateInvoiceCOCNumber"
              :state="form.invoice.coc_numberState"
              aria-describedby="coc_number-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="coc_number-feedback">
              {{ form.invoice.coc_numberError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            class="account-inputs"
            id="taxvat-group"
            key="invoice-taxvat"
            :label="$t('form_vat_number')"
            label-for="taxvat"
          >
            <b-form-input
              id="invoice-taxvat"
              v-model="form.invoice.taxvat"
              type="text"
              :state="form.invoice.taxvatState"
              @blur="validateInvoiceVAT"
              aria-describedby="taxvat-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="taxvat-feedback">
              {{ form.invoice.taxvatError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- <b-form-group
            class="account-inputs"
            id="taxvat-group"
            key="invoice-taxvat"
            :label="$t('form_vat_number')"
            label-for="taxvat"
          >
            <b-form-input
              id="invoice-taxvat"
              v-model="form.invoice.taxvat"
              type="text"
              :state="form.invoice.taxvatState"
              :disabled="isValidInvoiceVatNumber === false"
              aria-describedby="taxvat-feedback"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="showInvoiceVatMessage != ''"
              id="taxvat-feedback"
            >
              {{ showInvoiceVatMessage }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback id="taxvat-feedback">
              {{ form.invoice.taxvatError }}
            </b-form-invalid-feedback>
          </b-form-group> -->
        </div>

        <b-form-group
          class="account-inputs"
          id="first_name-group"
          key="invoice-first_name"
          :label="$t('form_first_name') + '*'"
          label-for="first_name"
        >
          <b-form-input
            id="first_name"
            v-model="form.invoice.firstname"
            type="text"
            v-on:blur="validateInvoiceFirstname"
            :state="form.invoice.firstnameState"
            aria-describedby="firstname-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="firstname-feedback">
            {{ form.invoice.firstnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="last_name-group"
          key="invoice-last_name"
          :label="$t('form_last_name') + '*'"
          label-for="last_name"
        >
          <b-form-input
            id="last_name"
            v-model="form.invoice.lastname"
            type="text"
            v-on:blur="validateInvoiceLastname"
            :state="form.invoice.lastnameState"
            aria-describedby="lastname-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="lastname-feedback">
            {{ form.invoice.lastnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="country-group"
          key="invoice-country"
          :label="$t('form_country') + '*'"
          label-for="country"
        >
          <b-form-select
            id="country"
            v-model="form.invoice.address.country_code"
            :options="countries"
            class="select"
          ></b-form-select>
        </b-form-group>

        <!-- v-if="!showFullInvoiceAddress" -->
        <b-form-group
          class="account-inputs"
          id="postcode-group"
          key="invoice-postcode"
          :label="$t('form_postal_code') + '*'"
          label-for="postcode"
        >
          <b-form-input
            id="invoice-postcode"
            v-model="form.invoice.address.postcode"
            type="text"
            :state="form.invoice.address.postcodeState"
            aria-describedby="address.postcode-feedback"
            v-on:blur="validateInvoicePostcode"
          ></b-form-input>
          <b-form-invalid-feedback id="address.postcode-feedback">
            {{ form.invoice.address.postcodeError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="showFullInvoiceAddress"
          class="account-inputs"
          id="street-group"
          key="invoice-street"
          :label="$t('form_street') + '*'"
          label-for="street"
        >
          <b-form-input
            id="street"
            v-model="form.invoice.address.streetDisplay"
            type="text"
            v-on:blur="validateInvoiceStreet"
            :state="form.invoice.address.streetDisplayState"
            aria-describedby="address.streetDisplay-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="address.streetDisplay-feedback">
            {{ form.invoice.address.streetDisplayError }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-row>
          <b-col sm="6" class="first">
            <b-form-group
              class="account-inputs"
              id="house_number-group"
              key="invoice-house_number"
              :label="$t('form_house_number') + '*'"
              label-for="invoice-house_number"
            >
              <b-form-input
                id="invoice-house_number"
                v-model="form.invoice.address.house_number"
                type="text"
                v-on:blur="validateInvoiceHouseNumber"
                :state="form.invoice.address.house_numberState"
                aria-describedby="address.house_number-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="address.house_number-feedback">
                {{ form.invoice.address.house_numberError }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="6" class="third">
            <b-form-group
              class="account-inputs"
              id="addition-group"
              key="invoice-addition"
              :label="$t('form_addition')"
              label-for="addition"
            >
              <b-form-input
                id="addition"
                v-model="form.invoice.address.addition"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- <b-form-group
          v-if="showFullInvoiceAddress"
          class="account-inputs"
          id="postcode-group1"
          :label="$t('form_postal_code') + '*'"
          label-for="postcode1"
        >
          <b-form-input
            id="postcode1"
            v-model="form.invoice.address.postcode"
            type="text"
            v-on:blur="validateInvoicePostcode()"
            :state="form.invoice.address.postcodeState"
            aria-describedby="address.postcode1-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="address.postcode1-feedback">
            {{ form.invoice.address.postcodeError }}
          </b-form-invalid-feedback>
        </b-form-group> -->

        <b-form-group
          v-if="showFullInvoiceAddress"
          class="account-inputs"
          id="city-group"
          key="invoice-city-group"
          :label="$t('form_city') + '*'"
          label-for="city"
        >
          <b-form-input
            id="city"
            v-model="form.invoice.address.city"
            type="text"
            v-on:blur="validateInvoiceCity"
            :state="form.invoice.address.cityState"
            aria-describedby="address.city-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="address.city-feedback">
            {{ form.invoice.address.cityError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          v-if="foundInvoiceAddress != '' && showFullInvoiceAddress == false"
          class="account-inputs"
          id="add-group"
          label=" "
          label-for="add"
        >
          <div
            id="add"
            :style="{ color: hasFoundInvoiceAddress === false && '#CB2E25' }"
          >
            {{ foundInvoiceAddress }}
          </div>
        </b-form-group>
        <b-form-group
          v-if="
            foundInvoiceAddresses.length > 0 && showFullInvoiceAddress == false
          "
          class="account-inputs"
          id="addm-group"
          label=" "
          label-for="addm"
        >
          <div id="addm">
            <b-form-radio
              style="width: 300px"
              class="account-radios"
              v-model="selectedInvoiceAddress"
              v-for="(item, index) in foundInvoiceAddresses"
              :key="`add-${index}`"
              name="some-radios"
              :value="index"
              >{{ item.text }}</b-form-radio
            >
          </div>
        </b-form-group>
        <b-form-checkbox
          v-if="showManual"
          id="manual2"
          v-model="showFullInvoiceAddress"
          name="manual2"
          >{{ $t("form_fillout_address_manual") }}</b-form-checkbox
        >
        <b-form-group
          class="account-inputs"
          id="telephone-group"
          key="invoice-telephone"
          :label="$t('form_phone') + '*'"
          label-for="telephone"
        >
          <b-form-input
            id="telephone"
            v-model="form.invoice.address.telephone"
            type="text"
            v-on:blur="validateInvoicePhone"
            :state="form.invoice.address.telephoneState"
            aria-describedby="address.telephone-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="address.telephone-feedback">
            {{ form.invoice.address.telephoneError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";
import {
  validatePostcode,
  validateVatNumber,
  checkEmailExist,
} from "@storefront/core/data-resolver/user";
import Cart from "@storefront/core/modules/cart/mixins";

export default {
  name: "NotLoggedInCheckoutDetails",
  mixins: [Cart],
  mounted() {
    if (typeof config.customers.defaultCountry != "undefined") {
      this.form.address.country_code = config.customers.defaultCountry;
      this.form.invoice.address.country_code = config.customers.defaultCountry;
    }
    if (config.postcode_validation.enabled == false) {
      this.manual = true;
      this.showManual = false;
    }
    if (config.vat_validation.enabled == false) {
      this.showVatCheck = false;
    }

    // Fill address
    this.setShippingAddressData(this.shippingAddress);

    if (this.isCartLoaded == true) this.isAddressLoaded = true;
  },

  methods: {
    setShippingAddressData(shippingAddressData) {
      if (this.guestEmail != null) {
        this.form.email = this.guestEmail;
      }
      if (shippingAddressData != null) {
        const address = shippingAddressData;

        const basicInfo = {};
        if (address.company != null) {
          this.isCompany = true;
          basicInfo.company = address.company;
        } else {
          this.isCompany = false;
        }

        if (address.vat_id != null) {
          basicInfo.taxvat = address.vat_id;
        } else {
          basicInfo.taxvat = "";
        }

        basicInfo.firstname = address.firstname;
        basicInfo.lastname = address.lastname;

        const addressInfo = {};
        addressInfo.streetDisplay = address.street[0];
        if (address.street[1] != undefined) {
          addressInfo.house_number = address.street[1];
        }
        if (address.street[2] != undefined) {
          addressInfo.addition = address.street[2];
        }
        addressInfo.postcode = address.postcode;
        addressInfo.city = address.city;
        addressInfo.telephone = address.telephone;
        addressInfo.country_code = address.country.code;

        this.form = {
          ...this.form,
          ...basicInfo,
          address: { ...this.form.address, ...addressInfo },
        };

        this.isAddressLoaded = true;
      }
    },
    async validateEmail() {
      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
      } else if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
      } else {
        if (!(await checkEmailExist(this.form.email))) {
          this.emailExists = true;
        } else {
          this.emailExists = false;

          if (this.form.email !== this.guestEmail) {
            await this.$store.dispatch("cart/addCartEmail", {
              email: this.form.email,
            });
          }
        }
        this.form.emailState = true;
      }
      this.validateAddress();
      if (this.form.address.default_billing != true) {
        this.validateInvoiceAddress();
      }
      this.validateRefInput("email", this.form.emailState);
    },
    async doLogin() {
      const loginOk = await this.$store.dispatch("user/login", {
        username: this.form.email,
        password: this.form.password,
      });

      if (loginOk == true) {
        const msg = {
          type: "success",
          title: this.$t("login_success"),
          text: this.$t("you_are_logged_in"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
        //this.$router.push({ name: "account" });
      }
    },
    async validateVAT(event) {
      if (this.isCompany == true) {
        if (this.form.taxvat != "") {
          if (
            this.form.taxvat.substring(0, 2).toUpperCase() ===
            this.form.address.country_code
          ) {
            const retval = await validateVatNumber(this.form.taxvat);
            Logger.debug("vatcheck", "validateVat", retval.valid)();
            if (retval.valid == "true") {
              this.isValidVatNumber = true;
              this.form.taxvatState = true;
            } else {
              this.isValidVatNumber = false;
              this.form.taxvatState = false;
              this.form.taxvatError = this.$t("taxvat_invalid");

              this.showVatMessage = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidVatNumber = false;
            this.form.taxvatState = false;
            this.form.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      } else {
        this.form.taxvatState = null;
      }

      if (event) {
        this.validateInput(event, this.form.taxvatState);
      } else {
        this.validateRefInput("taxvat", this.form.taxvatState);
      }
    },
    async validateAddress() {
      let isOk = true;
      if (this.isCompany == true) {
        if (this.form.company == "") {
          isOk = false;
        } else {
          if (this.form.companyState != true) {
            this.validateCompany();
            if (this.form.companyState != true) {
              isOk = false;
            }
          }
        }
        /*
        if (this.form.taxvat == "") {
          isOk = false;
        }
        */
      }
      if (this.form.firstname == "") {
        isOk = false;
      } else {
        if (this.form.firstnameState != true) {
          this.validateFirstname();
          if (this.firstnameState != true) {
            isOk = false;
          }
        }
      }

      if (this.form.lastname == "") {
        isOk = false;
      } else {
        if (this.form.lastnameState != true) {
          this.validateLastname();
          if (this.lastnameState != true) {
            isOk = false;
          }
        }
      }

      if (this.form.address.country_code == "") {
        isOk = false;
      }

      if (this.form.address.postcode == "") {
        isOk = false;
      }
      if (this.form.address.postcode != "") {
        if (this.form.address.postcodeState != true) {
          this.validatePostcode();
          if (this.form.address.postcodeState != true) {
            isOk = false;
          }
        }
      }

      if (this.form.address.streetDisplay == "") {
        isOk = false;
      } else {
        if (this.form.address.streetDisplayState != true) {
          this.validateStreet();
          if (this.form.address.streetDisplayState != true) {
            isOk = false;
            this.showFullAddress = true;
          }
        }
      }

      if (this.form.address.house_number == "") {
        isOk = false;
      } else {
        if (this.form.address.house_numberState != true) {
          this.validateHouseNumber();
          if (this.form.address.house_numberState != true) {
            isOk = false;
          }
        }
      }

      if (this.form.address.city == "") {
        isOk = false;
      } else {
        if (this.form.address.cityState != true) {
          this.validateCity();
          if (this.form.address.cityState != true) {
            isOk = false;
            this.showFullAddress = true;
          }
        }
      }

      if (this.form.address.telephone == "") {
        isOk = false;
      } else {
        if (this.form.address.telephoneState != true) {
          this.validatePhone();
          if (this.form.address.telephoneState != true) {
            isOk = false;
          }
        }
      }

      if (isOk == false) {
        this.removeShippingMethod();
      } else {
        if (this.form.emailState == null) {
          this.form.emailState = false;
          this.removeShippingMethod();
          return;
        }
        if (this.form.emailState == false || this.emailExists != false) {
          this.removeShippingMethod();
          return;
        }

        const address = {
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          postcode: this.form.address.postcode,
          city: this.form.address.city,
          telephone: this.form.address.telephone,
          street: [
            this.form.address.streetDisplay,
            this.form.address.house_number,
          ],
          country_code: this.form.address.country_code,
        };

        if (this.form.address.addition != "") {
          address.street.push(this.form.address.addition);
        }

        if (this.isCompany == true) {
          address.company = this.form.company;
          if (this.form.taxvat != "") {
            address.vat_id = this.form.taxvat;
          }
        } else {
          address.company = "";
          address.vat_id = "";
        }
        await this.$store.dispatch("cart/addShippingAddress", {
          address: address,
        });
        if (this.form.address.default_billing == true) {
          await this.$store.dispatch("cart/addBillingAddress", {
            address: address,
          });
        }
      }
    },
    async validateInvoiceAddress() {
      let isOk = true;
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.company == "") {
          isOk = false;
        }
        /*
        if (this.form.invoice.taxvat == "") {
          isOk = false;
        }
        */
      }
      if (this.form.invoice.firstname == "") {
        isOk = false;
      }
      if (this.form.invoice.lastname == "") {
        isOk = false;
      }
      if (this.form.invoice.address.country_code == "") {
        isOk = false;
      }
      if (this.form.invoice.address.postcode == "") {
        isOk = false;
      }
      if (this.form.invoice.address.postcode != "") {
        if (this.form.invoice.address.postcodeState != true) {
          this.validateInvoicePostcode();
          if (this.form.invoice.address.postcodeState != true) {
            isOk = false;
          }
        }
      }
      if (this.form.invoice.address.streetDisplay == "") {
        isOk = false;
      }
      if (this.form.invoice.address.house_number == "") {
        isOk = false;
      }
      if (this.form.invoice.address.city == "") {
        isOk = false;
      }
      if (this.form.invoice.address.telephone == "") {
        isOk = false;
      }

      if (isOk == true) {
        const address = {
          company: this.form.invoice.company,
          firstname: this.form.invoice.firstname,
          lastname: this.form.invoice.lastname,
          postcode: this.form.invoice.address.postcode,
          city: this.form.invoice.address.city,
          telephone: this.form.invoice.address.telephone,
          street: [
            this.form.invoice.address.streetDisplay,
            this.form.invoice.address.house_number,
          ],
          country_code: this.form.invoice.address.country_code,
        };

        if (this.form.invoice.address.addition != "") {
          address.street.push(this.form.invoice.address.addition);
        }

        if (this.form.invoice.isCompany == true) {
          address.company = this.form.invoice.company;
          if (this.form.invoice.taxvat != "") {
            address.vat_id = this.form.invoice.taxvat;
          }
        } else {
          address.company = "";
          address.vat_id = "";
        }

        this.$store.dispatch("cart/addBillingAddress", { address: address });
      }
    },
    validateCompany(event) {
      if (this.isCompany == true) {
        if (this.form.company == "") {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
        }
      } else {
        this.form.companyState = null;
      }
      this.validateInput(event, this.form.companyState);
    },
    validateFirstname(event) {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
      this.validateInput(event, this.form.firstnameState);
    },
    validateLastname(event) {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
      this.validateInput(event, this.form.lastnameState);
    },
    validateCOCNumber(event) {
      if (this.isCompany == true) {
        if (this.form.coc_number == "") {
          this.form.coc_numberState = false;
          this.form.coc_numberError = this.$t("coc_number_required");
        } else {
          this.form.coc_numberState = true;
        }
      } else {
        this.form.coc_numberState = null;
      }
      this.validateInput(event, this.form.coc_numberState);
    },
    validateStreet(event) {
      if (this.form.address.streetDisplay == "") {
        this.form.address.streetDisplayState = false;
        this.form.address.streetDisplayError = this.$t("street_required");
      } else {
        this.form.address.streetDisplayState = true;
      }
      this.validateInput(event, this.form.address.streetDisplayState);
    },
    validateHouseNumber(event) {
      if (this.form.address.house_number == "") {
        this.form.address.house_numberState = false;
        this.form.address.house_numberError = this.$t("house_number_required");
      } else {
        if (this.hasFoundAddress !== false) {
          this.form.address.house_numberState = true;
        } else {
          this.form.address.house_numberState = null;
        }
      }
      this.validateInput(event, this.form.address.house_numberState);
    },
    validateCity(event) {
      if (this.form.address.city == "") {
        this.form.address.cityState = false;
        this.form.address.cityError = this.$t("city_required");
      } else {
        this.form.address.cityState = true;
      }
      this.validateInput(event, this.form.address.cityState);
    },
    validatePhone(event) {
      if (this.form.address.telephone == "") {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.address.telephoneState = true;
      }
      this.validateInput(event, this.form.address.telephoneState);
    },
    async validatePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.address.postcode,
          this.form.address.house_number
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            let addition = list[0].letter;
            if (list[0].addition != null) {
              addition += list[0].addition;
            }
            this.form.address.addition = addition;
            address += address + addition;
          } else {
            this.form.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.address.city = list[0].city;
          this.form.address.cityState == true;
          this.foundAddress = address;
          this.hasFoundAddress = true;
          this.validatePostcode();
        } else if (list.length == 0) {
          this.hasFoundAddress = false;
          this.foundAddress = this.$t("postcode_not_found");

          this.form.address.streetDisplay = "";
          this.form.address.streetDisplayState = false;
          this.form.address.streetDisplayError = this.$t("street_required");

          this.form.address.city = "";
          this.form.address.cityState = false;
          this.form.address.cityError = this.$t("city_required");
          //Logger.debug("PostcodeValidation", "length 0", list.length)();
        } else {
          this.hasFoundAddress = true;
          list.forEach(function (item) {
            let address = item.street + " " + item.number;
            let addon = item.letter;
            if (addon != null) {
              if (item.addition != null) {
                addon += item.addition;
              }
              address += addon;
            }
            item.addon = addon;

            address = address + ", " + item.postcode + " " + item.city;
            item.text = address;
            Logger.debug("multiple addresses", "item", item)();

            this.foundAddresses.push(item);

            this.foundAddresses.sort((a, b) => {
              if (a.addon > b.addon) return 1;
              if (a.addon < b.addon) return -1;
              return 0;
            });
          }, this);
        }
      } else {
        if (this.form.address.postcode == "") {
          this.form.address.postcodeState = false;
          this.form.address.postcodeError = this.$t("postcode_required");
        } else {
          this.form.address.postcodeState = true;
        }
      }
      this.validateAddress();
    },
    validatePostcode(event) {
      const nl = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      const de = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/g;
      const be = /^[1-9][0-9]{3}$/g;
      if (
        this.form.address.country_code == "NL" &&
        nl.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
      } else if (
        this.form.address.country_code == "BE" &&
        be.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
      } else if (
        this.form.address.country_code == "DE" &&
        de.test(this.form.address.postcode)
      ) {
        this.form.address.postcodeState = true;
      } else {
        this.form.address.postcodeState = false;
        this.form.address.postcodeError = this.$t("postcode_required");
      }
      if (this.hasFoundAddress !== false) {
        if (event) {
          this.validateInput(event, this.form.address.postcodeState);
        } else {
          this.validateRefInput("postcode", this.form.address.postcodeState);
        }
      } else {
        this.form.address.postcodeState = null;
        this.validateRefInput("postcode", this.form.address.postcodeState);
      }
    },
    async validateInvoiceVAT(event) {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.taxvat != "") {
          if (
            this.form.invoice.taxvat.substring(0, 2).toUpperCase() ===
            this.form.invoice.address.country_code
          ) {
            const retval = await validateVatNumber(this.form.invoice.taxvat);
            Logger.debug("vatcheck", "validateVat", retval.valid)();
            if (retval.valid == "true") {
              this.isValidInvoiceVatNumber = true;
              this.form.invoice.taxvatState = true;
            } else {
              this.isValidInvoiceVatNumber = false;
              this.form.invoice.taxvatState = false;
              this.form.invoice.taxvatError = this.$t("taxvat_invalid");

              this.showInvoiceVatMessage = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidInvoiceVatNumber = false;
            this.form.invoice.taxvatState = false;
            this.form.invoice.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      } else {
        this.form.invoice.taxvatState = null;
      }
      if (event) {
        this.validateInput(event, this.form.invoice.taxvatState);
      } else {
        this.validateRefInput("invoice-taxvat", this.form.invoice.taxvatState);
      }
    },
    validateInvoiceCompany(event) {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.company == "") {
          this.form.invoice.companyState = false;
          this.form.invoice.companyError = this.$t("company_name_required");
        } else {
          this.form.invoice.companyState = true;
        }
      } else {
        this.form.invoice.companyState = null;
      }
      this.validateInput(event, this.form.invoice.companyState);
    },
    validateInvoiceFirstname(event) {
      if (this.form.invoice.firstname == "") {
        this.form.invoice.firstnameState = false;
        this.form.invoice.firstnameError = this.$t("firstname_required");
      } else {
        this.form.invoice.firstnameState = true;
      }
      this.validateInput(event, this.form.invoice.firstnameState);
    },
    validateInvoiceLastname(event) {
      if (this.form.invoice.lastname == "") {
        this.form.invoice.lastnameState = false;
        this.form.invoice.lastnameError = this.$t("lastname_required");
      } else {
        this.form.invoice.lastnameState = true;
      }
      this.validateInput(event, this.form.invoice.lastnameState);
    },
    validateInvoiceCOCNumber(event) {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.coc_number == "") {
          this.form.invoice.coc_numberState = false;
          this.form.invoice.coc_numberError = this.$t("coc_number_required");
        } else {
          this.form.invoice.coc_numberState = true;
        }
      } else {
        this.form.invoice.coc_numberState = null;
        this.validateInput(event, this.form.invoice.coc_numberError);
      }
    },
    validateInvoiceStreet(event) {
      if (this.form.invoice.address.streetDisplay == "") {
        this.form.invoice.address.streetDisplayState = false;
        this.form.invoice.address.streetDisplayError =
          this.$t("street_required");
      } else {
        this.form.invoice.address.streetDisplayState = true;
      }
      this.validateInput(event, this.form.invoice.address.streetDisplayState);
    },
    validateInvoiceHouseNumber(event) {
      if (this.form.invoice.address.house_number == "") {
        this.form.invoice.address.house_numberState = false;
        this.form.invoice.address.house_numberError = this.$t(
          "house_number_required"
        );
      } else {
        if (this.hasFoundInvoiceAddress !== false) {
          this.form.invoice.address.house_numberState = true;
        } else {
          this.form.invoice.address.house_numberState = null;
        }
      }
      this.validateInput(event, this.form.invoice.address.house_numberState);
    },
    validateInvoiceCity(event) {
      if (this.form.invoice.address.city == "") {
        this.form.invoice.address.cityState = false;
        this.form.invoice.address.cityError = this.$t("city_required");
      } else {
        this.form.invoice.address.cityState = true;
      }
      this.validateInput(event, this.form.invoice.address.cityState);
    },
    validateInvoicePhone(event) {
      if (this.form.invoice.address.telephone == "") {
        this.form.invoice.address.telephoneState = false;
        this.form.invoice.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.invoice.address.telephoneState = true;
      }
      this.validateInput(event, this.form.invoice.address.telephoneState);
    },
    async validateInvoicePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.invoice.address.postcode,
          this.form.invoice.address.house_number
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.invoice.address.streetDisplay = list[0].street;

          let addition = list[0].letter;
          if (addition != null) {
            if (list[0].addition != null) {
              addition += list[0].addition;
            }
            this.form.invoice.address.addition = addition;
            address += addition;
          } else {
            this.form.invoice.address.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.invoice.address.city = list[0].city;
          this.form.invoice.address.cityState == true;
          this.foundInvoiceAddress = address;
          this.hasFoundInvoiceAddress = true;
          this.validatePostcode();
        } else if (list.length == 0) {
          this.hasFoundInvoiceAddress = false;
          this.foundInvoiceAddress = this.$t("postcode_not_found");

          this.form.invoice.address.streetDisplay = "";
          this.form.invoice.address.streetDisplayState = false;
          this.form.invoice.address.streetDisplayError =
            this.$t("street_required");

          this.form.invoice.address.city = "";
          this.form.invoice.address.cityState = false;
          this.form.invoice.address.cityError = this.$t("city_required");
          //Logger.debug("PostcodeValidation", "length 0", list.length)();
        } else {
          this.hasFoundInvoiceAddress = true;
          list.forEach(function (item) {
            let address = item.street + " " + item.number;
            let addon = item.letter;
            if (addon != null) {
              if (item.addition != null) {
                addon += item.addition;
              }
              address += addon;
            }
            item.addon = addon;

            address = address + ", " + item.postcode + " " + item.city;
            item.text = address;
            Logger.debug("multiple addresses", "item", item)();

            this.foundInvoiceAddresses.push(item);

            this.foundInvoiceAddresses.sort((a, b) => {
              if (a.addon > b.addon) return 1;
              if (a.addon < b.addon) return -1;
              return 0;
            });
          }, this);
        }
      } else {
        if (this.form.invoice.address.postcode == "") {
          this.form.invoice.address.postcodeState = false;
          this.form.invoice.address.postcodeError =
            this.$t("postcode_required");
        } else {
          this.form.invoice.address.postcodeState = true;
        }
      }
      this.validateInvoiceAddress();
    },
    validateInvoicePostcode(event) {
      const nl = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      const de = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/g;
      const be = /^[1-9][0-9]{3}$/g;
      if (
        this.form.invoice.address.country_code == "NL" &&
        nl.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
      } else if (
        this.form.invoice.address.country_code == "BE" &&
        be.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
      } else if (
        this.form.invoice.address.country_code == "DE" &&
        de.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
      } else {
        this.form.invoice.address.postcodeState = false;
        this.form.invoice.address.postcodeError = this.$t("postcode_required");
      }
      if (this.hasFoundInvoiceAddress !== false) {
        if (event) {
          this.validateInput(event, this.form.invoice.address.postcodeState);
        } else {
          this.validateRefInput(
            "invoice-postcode",
            this.form.invoice.address.postcodeState
          );
        }
      } else {
        this.form.invoice.address.postcodeState = null;
        this.validateRefInput(
          "invoice-postcode",
          this.form.invoice.address.postcodeState
        );
      }
    },
    validateInput(event, isValid) {
      if (typeof event !== "undefined") {
        if (isValid == true) {
          event.target.parentElement.classList.add("valid");
        } else {
          event.target.parentElement.classList.remove("valid");
        }
      }
    },
    validateRefInput(id, isValid) {
      const element = document.getElementById(id);
      if (element) {
        if (isValid == true) {
          element.parentElement.classList.add("valid");
        } else {
          element.parentElement.classList.remove("valid");
        }
      }
    },
    removeShippingMethod() {
      this.$store.commit("cart/setShippingMethods", []);
      this.$store.commit("cart/setShippingMethod", null);
    },
  },
  computed: {
    guestEmail() {
      return this.$store.getters["cart/getEmail"];
    },
    isCartLoaded() {
      return this.$store.getters["cart/getCartIsLoaded"];
    },
    shippingAddress() {
      return this.$store.getters["cart/getShippingAddress"];
    },
    countries() {
      return this.$store.getters["user/getCountries"];
    },
    country() {
      return this.form.address.country_code;
    },
    address() {
      return `${this.isCompany}|${this.form.company}|${this.form.taxvat}|${this.form.firstname}|${this.form.lastname}|${this.form.address.country_code}|${this.form.address.city}|${this.form.address.streetDisplay}|${this.form.address.addition}|${this.form.address.telephone}||${this.form.address.city}`;
    },
    invoiceAddress() {
      return `${this.isInvoiceCompany}|${this.form.invoice.company}|${this.form.invoice.taxvat}|${this.form.invoice.firstname}|${this.form.invoice.lastname}|${this.form.invoice.address.country_code}|${this.form.invoice.address.city}|${this.form.invoice.address.streetDisplay}|${this.form.invoice.address.addition}|${this.form.invoice.address.telephone}||${this.form.invoice.address.city}`;
    },
    invoiceCountry() {
      return this.form.invoice.address.country_code;
    },
    postcodeHouseNumber() {
      return `${this.form.address.postcode}|${this.form.address.house_number}`;
    },
    invoicePostcodeHouseNumber() {
      return `${this.form.invoice.address.postcode}|${this.form.invoice.address.house_number}`;
    },
    vatNumber() {
      return this.form.taxvat;
    },
    invoiceVatNumber() {
      return this.form.invoice.taxvat;
    },
    Email() {
      return this.form.email;
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  watch: {
    shippingAddress(v) {
      if (this.isAddressLoaded != true) {
        this.setShippingAddressData(v);
      }
    },
    country() {
      if (config.postcode_validation.enabled) {
        if (!config.postcode_validation.countries.includes(this.country)) {
          this.showFullAddress = true;
        } else {
          this.showFullAddress = false;
        }
      } else {
        this.showFullAddress = true;
      }
      if (this.form.address.postcode.length > 0) {
        this.validatePostcode();
      }
      if (this.isCompany == true) {
        if (this.form.taxvat != "") {
          this.validateVAT();
        }
      }
    },
    address() {
      if (this.form.addressTimer !== null) {
        clearTimeout(this.form.addressTimer);
      }
      this.form.addressTimer = setTimeout(
        function (scope) {
          scope.validateAddress();
        },
        1000,
        this
      );
    },
    invoiceAddress() {
      if (this.form.invoiceAddressTimer !== null) {
        clearTimeout(this.form.invoiceAddressTimer);
      }
      this.form.invoiceAddressTimer = setTimeout(
        function (scope) {
          scope.validateInvoiceAddress();
        },
        1000,
        this
      );
    },
    invoiceCountry() {
      if (config.postcode_validation.enabled) {
        if (
          !config.postcode_validation.countries.includes(this.invoiceCountry)
        ) {
          this.showFullInvoiceAddress = true;
        } else {
          this.showFullInvoiceAddress = false;
        }
      } else {
        this.showFullInvoiceAddress = true;
      }
      if (this.form.invoice.address.postcode.length > 0) {
        this.validateInvoicePostcode();
      }
    },
    vatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.taxvatTimer !== null) {
          clearTimeout(this.form.taxvatTimer);
        }
        this.form.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateVAT();
          },
          1000,
          this
        );
      }
    },
    invoiceVatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.invoice.taxvatTimer !== null) {
          clearTimeout(this.form.invoice.taxvatTimer);
        }
        this.form.invoice.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateInvoiceVAT();
          },
          1000,
          this
        );
      }
    },
    postcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundAddress != "") {
          this.foundAddress = "";
        }
        if (this.foundAddresses.length > 0) {
          this.foundAddresses = [];
        }
        this.hasFoundAddress = null;
        //const [oldPostcode, oldHouseNumber] = oldVal.split("|");
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validatePostcodeHouseNumber();
            },
            2000,
            this
          );
        } else {
          this.validateAddress();
        }
      }
    },
    invoicePostcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundInvoiceAddress != "") {
          this.foundInvoiceAddress = "";
        }
        if (this.foundInvoiceAddresses.length > 0) {
          this.foundInvoiceAddresses = [];
        }
        this.hasFoundInvoiceAddress = null;
        //const [oldPostcode, oldHouseNumber] = oldVal.split("|");
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validateInvoicePostcodeHouseNumber();
            },
            2000,
            this
          );
        } else {
          this.validateInvoiceAddress();
        }
      }
    },
    selectedAddress(newVal) {
      Logger.debug("selectedAddress", "index", newVal)();
      this.form.address.streetDisplay = this.foundAddresses[newVal].street;

      if (this.foundAddresses[newVal].addon != null) {
        this.form.address.addition = this.foundAddresses[newVal].addon;
      } else {
        this.form.address.addition = "";
      }
      this.form.address.city = this.foundAddresses[newVal].city;
      this.validatePostcode();
    },
    selectedInvoiceAddress(newVal) {
      Logger.debug("selectedAddress", "index", newVal)();
      this.form.invoice.address.streetDisplay =
        this.foundInvoiceAddresses[newVal].street;
      if (this.foundInvoiceAddresses[newVal].addon != null) {
        this.form.invoice.address.addition =
          this.foundInvoiceAddresses[newVal].addon;
      } else {
        this.form.invoice.address.addition = "";
      }
      this.form.invoice.address.city = this.foundAddresses[newVal].city;
      this.validateInvoicePostcode();
    },
    Email() {
      if (this.form.emailTimer !== null) {
        clearTimeout(this.form.emailTimer);
      }
      this.form.emailTimer = setTimeout(
        function (scope) {
          scope.validateEmail();
        },
        1000,
        this
      );
    },
    showFullAddress(newVal) {
      if (newVal == false) {
        this.validatePostcodeHouseNumber();
      }
    },
    hasFoundAddress(newVal) {
      if (newVal == false) {
        this.form.address.postcodeState = null;
        this.validateRefInput("postcode", this.form.address.postcodeState);
        this.form.address.house_numberState = null;
        this.validateRefInput(
          "house_number",
          this.form.address.house_numberState
        );
      }
    },
    showFullInvoiceAddress(newVal) {
      if (newVal == false) {
        this.validateInvoicePostcodeHouseNumber();
      }
    },
    hasFoundInvoiceAddress(newVal) {
      if (newVal == false) {
        this.form.invoice.address.postcodeState = null;
        this.validateRefInput(
          "invoice-postcode",
          this.form.invoice.address.postcodeState
        );
        this.form.invoice.address.house_numberState = null;
        this.validateRefInput(
          "invoice-house_number",
          this.form.invoice.address.house_numberState
        );
      }
    },
    isCompany(newVal) {
      if (newVal == false) {
        this.form.company = "";
        this.form.companyState = null;
        this.form.coc_number = "";
        this.form.coc_numberState = null;
        this.form.taxvat = "";
        this.form.taxvatState = null;
      }
    },
    isInvoiceCompany(newVal) {
      if (newVal == false) {
        this.form.invoice.company = "";
        this.form.invoice.companyState = null;
        this.form.invoice.coc_number = "";
        this.form.invoice.coc_numberState = null;
        this.form.invoice.taxvat = "";
        this.form.invoice.taxvatState = null;
      }
    },
  },
  data() {
    return {
      couponCode: "",
      isCompany: false,
      isInvoiceCompany: false,
      isValidVatNumber: false,
      isValidInvoiceVatNumber: false,
      showFullAddress: false,
      showFullInvoiceAddress: false,
      showVatMessage: "",
      showInvoiceVatMessage: "",
      showVatCheck: false,
      addressTimer: null,
      invoiceAddressTimer: null,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      hasFoundAddress: null,
      foundInvoiceAddresses: [],
      foundInvoiceAddress: "",
      hasFoundInvoiceAddress: null,
      emailExists: null,
      selectedAddress: null,
      selectedInvoiceAddress: null,
      form: {
        email: "",
        emailState: null,
        emailError: "",
        emailTimer: null,
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        coc_number: "",
        coc_numberState: null,
        coc_numberError: "",
        coc_numberTimer: null,
        taxvat: "",
        taxvatState: null,
        taxvatError: "",
        taxvatTimer: null,
        address: {
          country_code: "",
          postcode: "",
          postcodeState: null,
          postcodeError: "",
          postcodeTimer: null,

          street: [],
          streetDisplay: "",
          streetDisplayState: null,
          streetDisplayError: "",
          streetDisplayTimer: null,

          house_number: "",
          house_numberState: null,
          house_numberError: "",
          house_numberTimer: null,

          addition: "",
          city: "",
          cityState: null,
          cityError: "",
          cityTimer: null,

          telephone: "",
          telephoneState: null,
          telephoneError: "",
          telephoneTimer: null,
          default_billing: true,
          default_shipping: true,
        },
        invoice: {
          isCompany: true,

          company: "",
          companyState: null,
          companyError: "",
          companyTimer: null,
          firstname: "",
          firstnameState: null,
          firstnameError: "",
          firstnameTimer: null,
          lastname: "",
          lastnameState: null,
          lastnameError: "",
          lastnameTimer: null,
          coc_number: "",
          coc_numberState: null,
          coc_numberError: "",
          coc_numberTimer: null,
          taxvat: "",
          taxvatState: null,
          taxvatError: "",
          taxvatTimer: null,
          address: {
            country_code: "",
            postcode: "",
            postcodeState: null,
            postcodeError: "",
            postcodeTimer: null,

            street: [],
            streetDisplay: "",
            streetDisplayState: null,
            streetDisplayError: "",
            streetDisplayTimer: null,

            house_number: "",
            house_numberState: null,
            house_numberError: "",
            house_numberTimer: null,

            addition: "",
            city: "",
            cityState: null,
            cityError: "",
            cityTimer: null,

            telephone: "",
            telephoneState: null,
            telephoneError: "",
            telephoneTimer: null,
            default_billing: true,
            default_shipping: true,
          },
        },
        is_subscribed: false,
        checkbox2: false,
        manual: false,
      },
      isAddressLoaded: false,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
