<template>
  <div class="second-col">
    <div class="group">
      <span class="group-title"
        ><i class="fas fa-truck-moving"></i>{{ $t("shipping_method") }}</span
      >
      <div v-if="deliveryOptions.length == 0" class="note">
        {{ $t("fill_address_information") }}
      </div>
      <ul class="delivery-options-list list-unstyled pl-0 m-0">
        <li
          v-for="(deliveryOption, index) of deliveryOptions"
          :key="`ship-${index}`"
        >
          <b-form-radio
            class="pl-30"
            v-model="selectedShippingMethod"
            name="deliveryOption"
            :value="`${deliveryOption.carrier_code}|${deliveryOption.method_code}`"
          >
            <div class="delivery-price-wrap">
              <span class="price">{{
                formatCurrency(deliveryOption.amount.value)
              }}</span>
              <span class="lable">{{ deliveryOption.method_title }}</span>
            </div>
          </b-form-radio>
        </li>
      </ul>
    </div>
    <div class="group">
      <span class="group-title"
        ><i class="fas fa-credit-card"></i>{{ $t("payment_method") }}</span
      >

      <div v-if="selectedShippingMethod == null" class="note">
        {{ $t("fill_shipping_options") }}
      </div>
      <div v-if="selectedShippingMethod != null">
        <ul class="payment-options-list">
          <li
            v-for="(paymentOption, index) in paymentOptions"
            :key="`pay-${index}`"
          >
            <b-form-radio
              class=""
              name="paymentOption"
              :value="paymentOption.code"
              v-model="selectedPaymentMethod"
            >
              <!-- v-on:change="checkAllSubOptions(paymentOption.code)" -->
              <div class="d-flex align-items-center">
                <div class="payment-option-img">
                  <img
                    :src="`${app_url}/media/graphql/icons/50x32/${paymentOption.code}.png`"
                    :alt="paymentOption.code"
                  />
                </div>
                <span>{{ paymentOption.label }}</span>
              </div>
            </b-form-radio>
            <div
              v-for="(
                paymentSubOption, index
              ) in paymentOption.additional_fields"
              :key="`pso-${index}`"
            >
              <div v-if="paymentSubOption.type == 'select'" class="bank-select">
                <span class="d-block mb-15">{{ paymentSubOption.label }}</span>
                <b-form-group class="select--custom">
                  <b-form-select
                    :options="paymentSubOption.options"
                    text-field="label"
                    :value="
                      paymentMethodOptions[paymentOption.code][
                        paymentSubOption.code
                      ]
                    "
                    v-on:change="
                      setPaymentMethodOptions(
                        paymentOption.code,
                        paymentSubOption.code,
                        $event
                      )
                    "
                  >
                  </b-form-select>
                </b-form-group>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";
import Cart from "@storefront/core/modules/cart/mixins";
import { isIOS, isAndroid, isMacOs } from "mobile-device-detect";

export default {
  name: "CheckoutPayment",
  data: () => ({
    app_url: config.app_url,
  }),
  mixins: [Cart],
  computed: {
    deliveryOptions() {
      return this.$store.getters["cart/getShippingMethods"];
    },
    paymentOptions() {
      var options = this.$store.getters["cart/getPaymentMethods"];
      let newOptions = [];
      options.forEach((v) => {
        if (/applepay$/.test(v.code)) {
          if (isIOS || isMacOs) {
            newOptions.push(v);
          }
        } else if (/googlepay$/.test(v.code)) {
          if (isAndroid) {
            newOptions.push(v);
          }
        } else {
          newOptions.push(v);
        }
      });
      return newOptions;
    },
    paymentMethodOptions() {
      return this.$store.getters["cart/getPaymentMethodOptions"];
    },
    cartTotalWithOutTax: function () {
      if (this.$store.getters["cart/getCartIsLoaded"] == true) {
        const prices = this.$store.getters["cart/getCartPrices"];
        if (typeof prices != "undefined") {
          if (typeof prices.subtotal_excluding_tax != "undefined") {
            if (typeof prices.subtotal_excluding_tax.value != "undefined") {
              return prices.subtotal_excluding_tax.value;
            }
          }
        }
        return 0;
      } else {
        return 0;
      }
    },
    selectedShippingMethod: {
      get: function () {
        const shmed = this.$store.getters["cart/getShippingMethod"];
        Logger.debug("selectedShippingMethod-get", "checkOut", shmed)();
        if (shmed != null) {
          return shmed.carrier_code + "|" + shmed.method_code;
        } else {
          return null;
        }
      },
      set: function (value) {
        const delOption = value.split("|");
        Logger.debug("selectedShippingMethod-set", "checkOut", value)();
        this.$store.dispatch("cart/addShippingMethod", {
          carrier: delOption[0],
          method: delOption[1],
        });
        if (this.$gtm.enabled()) {
          let prod = [];
          let items = [];
          this.cartItems.forEach((element) => {
            let i = {
              id: element.product.sku,
              name: element.product.name,
              quantity: element.quantity,
            };
            let it = {
              item_id: element.product.sku,
              item_name: element.product.name,
              quantity: element.quantity,
              price: element.prices.price.value,
            };
            prod.push(i);
            items.push(it);
          });
          if (!this.$gtm.uaDisabled) {
            //GA event
            window.dataLayer.push({
              event: "eec.checkout",
              ecommerce: {
                checkout: {
                  actionField: {
                    step: 3,
                    option: value,
                    action: "checkout",
                  },
                  products: prod,
                },
              },
            });
          }
          if (this.$gtm.ga4Enabled) {
            //GA4 event
            window.dataLayer.push({
              event: "add_shipping_info",
              ecommerce: {
                add: {
                  currency: "EUR",
                  value: this.cartTotalWithOutTax,
                  shipping_tier: value,
                  items: items,
                },
              },
            });
          }
        }
      },
    },
    selectedPaymentMethod: {
      get: function () {
        return this.$store.getters["cart/getPaymentMethod"];
      },
      set: function (value) {
        this.$store.commit("cart/setPaymentMethod", value);
        if (this.$gtm.enabled()) {
          let prod = [];
          let items = [];
          this.cartItems.forEach((element) => {
            let i = {
              id: element.product.sku,
              name: element.product.name,
              quantity: element.quantity,
            };
            let it = {
              item_id: element.product.sku,
              item_name: element.product.name,
              quantity: element.quantity,
              price: element.prices.price.value,
            };
            prod.push(i);
            items.push(it);
          });
          window.dataLayer.push({
            event: "eec.checkout",
            ecommerce: {
              checkout: {
                actionField: {
                  step: 2,
                  option: value,
                  action: "checkout",
                },
                products: prod,
              },
            },
          });
          if (this.$gtm.ga4Enabled) {
            //GA4 event
            window.dataLayer.push({
              event: "add_payment_info",
              ecommerce: {
                add: {
                  currency: "EUR",
                  value: this.cartTotalWithOutTax,
                  payment_type: value,
                  items: items,
                },
              },
            });
          }
        }
      },
    },
  },
  methods: {
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    setPaymentMethodOptions(key, sub, value) {
      this.$store.commit("cart/updateCartPaymentMethodOptions", {
        key: key,
        sub: sub,
        value: value,
      });
    },
  },
};
</script>

<style>
</style>
