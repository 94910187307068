<template>
  <div class="third-col">
    <span class="group-title"
      ><i class="fas fa-eye"></i>{{ $t("order_overview") }}</span
    >
    <div class="cart-details">
      <ClientOnly>
        <b-link
          href="#"
          v-b-toggle.cart-details-collapse
          class="collapse-opener"
          >{{ $t("items_in_cart", { numberOf: cartCount }) }}
          <i class="fas fa-chevron-right"></i
        ></b-link>
      </ClientOnly>
      <b-collapse id="cart-details-collapse" visible>
        <div
          class="single-product"
          v-for="cartItem of cartItems"
          :key="cartItem.id"
        >
          <b-link href="#" class="remove" @click="removeItem(cartItem.id)">
            <b-icon icon="trash" aria-hidden="true"></b-icon>
          </b-link>
          <div class="img">
            <img
              :src="cartItem.product.image.url"
              :alt="cartItem.product.name"
            />
          </div>
          <div class="details">
            <span class="name">{{ cartItem.product.name }}</span>

            <div class="price-part">
              <div class="quantity-input d-flex">
                <b-link
                  href="#"
                  class="step-down-btn"
                  @click="quantityDown(cartItem.id, cartItem.quantity)"
                ></b-link>
                <input
                  type="number"
                  min="1"
                  :value="cartItem.quantity"
                  name="quantity"
                  ref="inputQuantity"
                />
                <b-link
                  href="#"
                  class="step-up-btn"
                  @click="quantityUp(cartItem.id, cartItem.quantity)"
                ></b-link>
              </div>
              <div class="price-text">
                {{
                  formatCurrency(cartItem.prices.row_total_including_tax.value)
                }}
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="order-overview">
      <span class="group-subtitle">{{ $t("order_overview") }}</span>
      <ul class="cost-list">
        <li class="">
          <span class="d-block mr-20">{{ $t("subtotal") }}</span>
          <span class="d-block">{{ formatCurrency(cartSubTotalPrice) }}</span>
        </li>
        <li
          v-for="(price, index) in cartTotalDiscounts"
          :key="`price-${index}`"
          class=""
        >
          <span class="d-block mr-20">{{ price.label }}</span>
          <span class="d-block"
            >- {{ formatCurrency(price.amount.value) }}</span
          >
        </li>
        <li v-if="shippingMethod != null" class="">
          <span class="d-block mr-20"
            >{{ shippingMethod.method_title  }}</span
          >
          <span class="d-block">{{
            formatCurrency(shippingMethod.amount_including_tax.value)
          }}</span>
        </li>
        <li
          v-for="(price, index) in cartTotalTaxPrices"
          :key="`tax-${index}`"
          class=""
        >
          <span class="d-block mr-20">{{ price.label }}</span>
          <span class="d-block">{{ formatCurrency(price.amount.value) }}</span>
        </li>
      </ul>
      <div class="total-grp">
        <span class="total">{{ $t("total") }}</span>
        <span class="text-bold">{{ formatCurrency(cartTotalPrice) }}</span>
      </div>
    </div>
    <div class="coupon pt-15 mb-15">
      <ClientOnly>
        <b-link
          class="collapse-opener group-subtitle border-0"
          v-b-toggle.coupon-collapse
          >{{ $t("coupon_discount") }} <i class="fas fa-chevron-right"></i
        ></b-link>
      </ClientOnly>
      <b-collapse id="coupon-collapse" collapsed class="pb-15">
        <div class="coupon-wrap">
          <b-form-input
            v-model="couponCode"
            type="text"
            :placeholder="$t('fill_out_discount_code')"
            class="coupon-input"
          ></b-form-input>
          <b-button @click="addCoupon" class="c-btn c-btn-primary">{{
            $t("add")
          }}</b-button>
        </div>
      </b-collapse>
    </div>
    <div class="coupon">
      <ClientOnly>
        <b-link
          class="collapse-opener group-subtitle border-0"
          v-b-toggle.remark-collapse
        >
          Opmerking plaatsen bij uw bestelling?
          <i class="fas fa-chevron-right"></i>
        </b-link>
      </ClientOnly>
      <b-collapse id="remark-collapse" collapsed class="pb-15">
        <div class="remark-wrap">
          <b-form-textarea
            v-model="customerNote"
            rows="3"
            max-rows="6"
            class="remark-input"
          ></b-form-textarea>
          <b-button @click="addRemark" class="c-btn c-btn-primary">{{
            $t("add")
          }}</b-button>
        </div>
      </b-collapse>
    </div>
    <div class="permission">
      <b-form-checkbox
        class="pl-30"
        id="newsletter"
        v-model="SubscribeNewsletter"
        name="news-letter-subscribe"
        value="yes"
        unchecked-value="no"
        >{{ $t("subscription_newsletter") }}</b-form-checkbox
      >
      <!-- <span class="terms">{{ $t("terms_warning") }}</span> -->
      <span class="terms">
        {{ $t("checkout-line-1") }}
        <b-link to="/algemene-voorwaarden" class="text-uppercase">{{
          $t("terms_and_conditions")
        }}</b-link>
        en
        <b-link to="/privacy-policy"> {{ $t("privacy_policy") }}</b-link>
      </span>
      <b-button
        @click="submitForm"
        v-bind:disabled="btnDisable"
        variant="success"
        class="checkout-btn"
        >{{ $t("pay") }}</b-button
      >
    </div>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";
import Cart from "@storefront/core/modules/cart/mixins";
import { getCustomerCartToken } from "@storefront/core/data-resolver/cart";

export default {
  name: "CheckoutOverview",
  components: {
    ClientOnly,
  },
  mixins: [Cart],

  data: () => ({
    couponCode: "",
    customerNote: "",
    SubscribeNewsletter: "no",
  }),

  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    isCreateAccount() {
      return this.$store.getters["checkout/getCreateAccount"];
    },
    shippingMethod() {
      return this.$store.getters["cart/getShippingMethod"];
    },
    btnDisable() {
      if (this.$store.getters["cart/getShippingAddress"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getBillingAddress"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getShippingMethod"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getPaymentMethod"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getEmail"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getItemsTotalQuantity"] == 0) {
        return true;
      }
      return false;
    },
    cartTotalWithOutTax: function() {
      if (this.$store.getters["cart/getCartIsLoaded"] == true) {
        const prices = this.$store.getters["cart/getCartPrices"];
        if (typeof prices != "undefined") {
          if (typeof prices.subtotal_excluding_tax != "undefined") {
            if (typeof prices.subtotal_excluding_tax.value != "undefined") {
              return prices.subtotal_excluding_tax.value;
            }
          }
        }
        return 0;
      } else {
        return 0;
      }
    },
    cartSubTotal() {
      if (this.$store.getters["cart/getCartIsLoaded"] == true) {
        const prices = this.$store.getters["cart/getCartPrices"];
        if (typeof prices != "undefined") {
          if (typeof prices.subtotal_including_tax != "undefined") {
            if (typeof prices.subtotal_including_tax.value != "undefined") {
              return prices.subtotal_including_tax.value;
            }
          }
        }
        return 0;
      } else {
        return 0;
      }
    },
    totalCartTax: function() {
      if (this.$store.getters["cart/getCartIsLoaded"] == true) {
        const prices = this.$store.getters["cart/getCartPrices"];
        let totalTax = { value: 0 };
        if (
          typeof prices != "undefined" &&
          typeof prices.applied_taxes == "object"
        ) {
          prices.applied_taxes.forEach(function(amount) {
            Logger.debug(
              "totalCartTax amount",
              "cartList",
              amount.amount.value
            )();
            totalTax.value += amount.amount.value;
          });
        }
        Logger.debug("totalCartTax", "cartList", [
          { amount: totalTax, label: `$t("VAT")` },
        ])();
        return [{ amount: totalTax, label: this.$t("VAT") }];
      }
      //return [{ amount: {'value':0}, label: this.$t("VAT") }];
      return this.cartTotalTaxPrices;
    },
  },
  mounted() {
    const bcrumb = { current: this.$t("checkout"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    if (this.$gtm.enabled()) {
      let prod = [];
      let items = [];
      this.cartItems.forEach((element) => {
        let i = {
          id: element.product.sku,
          name: element.product.name,
          quantity: element.quantity,
        };
        prod.push(i);
      });
      this.cartItems.forEach((element) => {
        let i = {
          item_id: element.product.sku,
          item_name: element.product.name,
          quantity: element.quantity,
          currency: "EUR",
          price: element.prices.price.value,
        };
        items.push(i);
      });
      if (!this.$gtm.uaDisabled) {
        //GA event
        window.dataLayer.push({
          event: "eec.checkout",
          ecommerce: {
            checkout: {
              actionField: {
                step: 1,
                option: "",
                action: "checkout",
              },
              products: prod,
            },
          },
        });
      }
      if (this.$gtm.ga4Enabled) {
        //GA4 event
        window.dataLayer.push({
          event: "begin_checkout",
          ecommerce: {
            add: {
              currency: "EUR",
              value: this.cartTotalWithOutTax,
              items: items,
            },
          },
        });
      }
    }
  },
  methods: {
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    async addCoupon() {
      if (this.couponCode != "") {
        const retval = await this.$store.dispatch("cart/addCouponCode", {
          code: this.couponCode,
        });
        Logger.debug("addCoupon", "cart.vue", retval)();
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: this.$t("coupon_code_ok", { code: this.couponCode }),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.couponCode = "";
        }
      }
    },
    async addRemark() {
      if (this.customerNote != "") {
        this.$store.commit("cart/setComment", this.customerNote);
      }
    },
    async submitForm() {
      if (this.isCreateAccount == true) {
        const formData = this.$store.getters["checkout/getCreateAccountData"];

        if (!formData) return;

        formData.address.street = formData.address.streetDisplay;
        if (formData.address.house_number != "") {
          formData.address.street =
            formData.address.street + " " + formData.address.house_number;
        }
        if (formData.address.addition != "") {
          formData.address.street =
            formData.address.street + "" + formData.address.addition;
        }
        if (this.isCompany != true) {
          formData.company = "";
          formData.taxvat = "";
        }

        const retval = await this.$store.dispatch("user/createAccount", {
          account: formData,
        });
        Logger.debug("create Account", "retval", retval)();

        if (retval == false) {
          return;
        }

        const msg = {
          type: "success",
          title: "account_create",
          text: this.$t("account_created"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
      // return;

      const retval = await this.$store.dispatch("cart/addPaymentMethod");
      Logger.debug("retval", "submitForm", retval)();
      if (retval != false) {
        localStorage.removeItem("orderHash");
        if (retval.order.hash) {
          localStorage.setItem("orderHash", retval.order.hash);
        }
        const url = retval.redirect_url.replace(config.app_url, "");
        // this.$store.dispatch("cart/unLoad");
        if (this.isLoggedIn == true) {
          await this.$store.dispatch("user/loadUser");
          const newCartToken = await getCustomerCartToken();
          this.$store.commit("cart/setServerToken", newCartToken);
        }
        if (url.includes("https://")) {
          window.location = url;
        } else {
          this.$router.push({
            path: url + "?transactionid=" + retval.order.increment_id,
            params: { hash: retval.order.hash },
          });
          this.$store.dispatch("cart/loadCart");
        }
      }
      Logger.debug("submitForm", "checkout", retval)();
    },
  },
};
</script>

<style></style>
