<template>
  <div class="checkout-step-page">
    <Breadcrumbs />
    <section class="checkout-detail-page">
      <b-container>
        <div class="row -mx-10">
          <b-col lg="4" class="px-10">
            <CheckoutAddress />
          </b-col>
          <b-col lg="4" class="px-10">
            <CheckoutPayment />
          </b-col>
          <b-col lg="4" class="px-10">
            <CheckoutOverview />
          </b-col>
        </div>
      </b-container>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs.vue";
import CheckoutAddress from "@/base/core/components/checkout/CheckoutAddress.vue";
import CheckoutPayment from "@/base/core/components/checkout/CheckoutPayment.vue";
import CheckoutOverview from "@/esf_antarctica_topdesign/core/components/checkout/CheckoutOverview.vue";

export default {
  name: "CheckoutPage",
  components: {
    Breadcrumbs,
    CheckoutAddress,
    CheckoutPayment,
    CheckoutOverview,
  },
  data: () => ({}),
  metaInfo() {
    return {
      title: "Checkout",
      // all titles will be injected into this template
    };
  },
  watch: {
    "$store.state.cart.cartQuantity": {
      immediate: true,
      handler: function (cartQty) {
        if (cartQty === 0) {
          this.$router.push("/checkout/cart");
        }
      },
    },
  },
};
</script>

<style lang="scss">
</style>
